import {useEffect, useState} from 'react'
import ContactList from '../../components/contactlist/ContactList.js';
import RecordView from '../../components/recordview/RecordView.js';
import "./Dashboard.css";

export default function Dashboard(props) {

    const [numUnaccepted, setNumUnaccepted] = useState(0);
    const [numAppsUncontacted, setNumAppsUncontacted] = useState(0);
    const [numQueriesUncontacted, setNumQueriesUncontacted] = useState(0);
    const [filteredList, setFilteredList] = useState(null);
    const [activeListType, setActiveListType] = useState("App");    
    const [activeFilter, setActiveFilter] = useState("All");
    const [displayRecord, setDisplayRecord] = useState({});

    useEffect( () => {

        fetch( "/api/v1/application/all/num-unaccepted", {
            method: "GET",
            credentials: "include"
        })
        .then(res => res.json())
        .then( (data) => {
            if(data && data.value) {
                setNumUnaccepted(data.value);
            }
        });

        fetch( "/api/v1/application/all/num-uncontacted", {
            method: "GET",
            credentials: "include"
        })
        .then(res => res.json())
        .then( (data) => {
            if(data && data.value) {
                setNumAppsUncontacted(data.value);
            }
        });

    },[]);

    function loadRecord(id) {
        if(activeListType == "App") {
            getRowOfApplication(id);
            return;
        }

        getRowOfQuery(id);
        return;
    }


    function getRowOfApplication(id) {
        fetch( `/api/v1/application/${id}`, {
            method: "GET",
            credentials: "include"
        })
        .then(res => res.json())
        .then( (data) => {
            console.log(data);
            if(data) {
                setDisplayRecord(data);
            }
        });
    }

    function getRowOfQuery(id) {
        fetch( `/api/v1/contact/${id}`, {
            method: "GET",
            credentials: "include"
        })
        .then(res => res.json())
        .then( (data) => {
            if(data) {
                setDisplayRecord(data);
            }
        });
    }


    function resetFilter(){
        if(activeListType == "App") {
            filterNoneApps();
            setActiveFilter("All");
            return;
        }

        filterNoneQueries();
        setActiveFilter("All");
        return;
    }


    function filterNoneQueries() {
        fetch( "/api/v1/contact/", {
            method: "GET",
            credentials: "include"
        })
        .then(res => { if (res.status == 200) return res.json()})
        .then( (data) => {
            if(data) {
                setFilteredList(data);
                setActiveFilter("All");
            }
        });
    }

    function filterUncontactedQueries() {
        fetch( "/api/v1/contact/all/uncontacted", {
            method: "GET",
            credentials: "include"
        })
        .then(res => { if (res.status == 200) return res.json()})
        .then( (data) => {
            if(data) {
                setFilteredList(data);
                setActiveFilter("Uncontacted");
            }
        });
    }

    function filterUncontacted() {
        if(activeListType =="App") {
            filterUncontactedApps();
            return;
        }

        filterUncontactedQueries();
        return;
    }

    function filterUnacceptedApps() {
        fetch( "/api/v1/application/all/unaccepted", {
            method: "GET",
            credentials: "include"
        })
        .then(res => {if (res.status == 200) return res.json()})
        .then( (data) => {
            if(data) {
                setFilteredList(data);
                setActiveFilter("Unaccepted");
            }
        });

    }

    function filterUncontactedApps() {
        fetch( "/api/v1/application/all/uncontacted", {
            method: "GET",
            credentials: "include"
        })
        .then(res => { if (res.status == 200) return res.json()})
        .then( (data) => {
            if(data) {
                setFilteredList(data);
                setActiveFilter("Uncontacted");
            }
        });
    }

    function filterNoneApps() {
        fetch( "/api/v1/application/", {
            method: "GET",
            credentials: "include"
        })
        .then(res => {if (res.status == 200) return res.json()})
        .then( (data) => {
            if(data) {
                setFilteredList(data);
                setActiveFilter("All");
            }
        });
    }

    function setListTypeAs(newType) {
        if(newType =="App") {
            setActiveListType("App");
            filterNoneApps();
            return;
        }

        if(newType =="Query") {
            setActiveListType("Query");
            filterNoneQueries();
            return;
        }
    }

return (
<>
   <div className="dashboard-main-section">
            <ContactList activeListType={activeListType}  loadRecord={loadRecord} setListType={setListTypeAs} filteredList={filteredList} filterNone={resetFilter} numUnaccepted={numUnaccepted} numUncontacted={activeListType == "App" ? numAppsUncontacted : numQueriesUncontacted} filterUncontacted={filterUncontacted} filterUnacceptedApps={filterUnacceptedApps} />
            <RecordView mode={activeListType == "App" ? "application" : "contact"} data={displayRecord}/>
    </div>
</>)

}