import "./NavigationBar.css";
import { Link } from 'react-router-dom';
import gsap from "gsap";
import React, { useEffect, useRef, useState } from 'react';
import ApplyButton from '../applybutton/ApplyButton.js'
export default function NavigationBar(props) {


    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div className="NavlistContainer">
            <div className={`Navlist-inner ${isMenuOpen ? 'active' : ''}`}>
                <ul className="Navlist">
                    <li className="Navitem">
                        <Link to="./">Home</Link>
                    </li>
                    <li className="Navitem">
                        <Link to="./services">Services</Link>
                    </li>
                    <li className="Navitem">
                        <Link to="./about">About Us</Link>
                    </li>
                    <li className="Navitem">
                        <Link to="./faq">FAQ</Link>
                    </li>
                    <li>
                        <ApplyButton />
                    </li>
                </ul>
            </div>
            <div className="burger-menu" onClick={toggleMenu}>
            <div className="burger-bar"></div>
            <div className="burger-bar"></div>
            <div className="burger-bar"></div>
            </div>
        </div>
    )
}