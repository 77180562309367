
import Faq from "react-faq-component";
import './Faq.css';
import '../Shapes.css'
import Contact from "../../components/contactsection/ContactSection.js";
import Footer from '../../components/footer/Footer.js';

export default function FrequentlyAsked() {

    const contactData = {
        title: "Contact",
        rows: [
            {
                title: "How can I contact Onyx?",
                content: `You can contact us under the 'Let’s get in touch' section towards the bottom of the page.`,
            },
            {
                title: "How long does it usually take to get a response?",
                content:
                    "Someone from the support team will aim to contact you within 48 hours. In case of high call and message volumes, this may take up to 72 hours.",
            }
        ],
    };

    const applicationData = {
        title: "Application",
        rows: [
            {
                title: "Do you accept all applicants?",
                content: `Our applicant acceptance varies based on a variety of factors. When you apply, please be sure to submit all the correct information for us to provide you with the best possible options for your particular situation. There is no single factor that determines acceptance, and applying will not hurt your future chances of being accepted`,
            },
            {
                title: "How can I start my application?",
                content:
                    "Throughout our website, you will find several 'Apply Now' buttons, including one in the navigation bar at the top right corner of the page. Once you have submitted your application with all the required information, we will respond within 48 hours to schedule a video call and discuss the next steps.",
            },
            {
                title: "What if I have questions before applying?",
                content: `Under the "Let’s get in touch" section, you can submit any and all questions you may have about the process. If you would like to arrange a call to discuss your questions, you may also contact us through the “Let’s get in touch” section , and we will schedule a time that suits you best`
            },
        ],
    };

    const commitmentData = {
        title: "Time Commitment",
        rows: [
            {
                title: "How much time to I need to commit to be successful?",
                content: "There is no set time per week that you need to commit to. However, your success and chances of a contract extension depend greatly on the amount of work you put in and your ability to maintain a reasonable workload."
            },
            {
                title: "What is the expected workload?",
                content: "Your weekly workload largely depends on the extent to which we assist you. Typically, we handle all tasks except for content filming. On average, you can anticipate spending 5-10 hours per week on filming. As mentioned earlier, dedicating more time can lead to greater success as we take care of the rest."

            }
        ],
    };

    const paymentData = {
        title: "Payment",
        rows: [
            {
                title: "How often are models paid?",
                content: "Currently, we go on a biweekly pay schedule."
            },
            {
                title: "How do I receive a payment",
                content: "In general, we facilitate payments to our models through direct deposit, following the laws and regulations of their respective countries. Alternative payment options are available for clients unable to utilize direct deposit. Payments are processed on a biweekly basis."
            }
        ],
    };

    const accountData = {
        title: "Account Management",
        rows: [
            {
                title: "How often will I be on contact with my account manager?",
                content: "When you sign up with Onyx, you will be assigned an account manager from our team of experts. This account manager will be available to you 24/7, and if the manager is not available, we will connect you with someone who can assist you temporarily."
            },
            {
                title: "How do I contact my account manager?",
                content: "Upon signing up, you will be provided with your account manager's phone number and email address. You can use these contacts to call, text, video call, or email them with any questions you may have. Your account manager will also use these channels to stay in contact with you, update you on your daily tasks, and provide progress reports."
            },
            {
                title: "Who is managing my content?",
                content: "Your content is managed by your account manager and stored securely. We employ the latest and best encryption technology to ensure the safety of your private information and content."
            }
        ],
    };

    const startingData = {
        title: "Getting Started",
        rows: [
            {
                title: "Does experience matter?",
                content: "While experience in the industry does help, it is not a requirement for acceptance. We work with models of all different incomes, experience levels, and skills."
            },
            {
                title: "What marketing strategies do you employ?",
                content: "Our primary focus is on leveraging social media platforms, including TikTok, Instagram, Twitter, Snapchat, YouTube, Tinder, Bumble, Reddit, and more. Through these channels, we employ both organic growth strategies and, depending on your preferences and requirements, paid promotional methods."
            },
            {
                title: "I got rejected, what happens now?",
                content: "We take careful consideration when accepting clients. If you receive a rejection letter, we offer an explanation as to why we feel you were not the best fit for our team and provide suggestions on how you can possibly get accepted in the future. There is a one-month waiting period before you can apply again, and being rejected does not affect your chances of getting accepted in the future."
            },
            {
                title: "Are there any upfront fees associated with working with Onyx?",
                content: "We do not impose any upfront costs on our models, as the majority of our clients achieve organic growth through free marketing efforts. However, we do offer paid marketing strategies for select clients if needed, and the costs associated with such campaigns are deducted from their monthly earnings."
            }
        ],
    };

    const contentData = {
        title: "Content",
        rows: [
            {
                title: "Who owns the content I produce?",
                content: "All content is owned by you. When you sign a contract with us, you are only granting us permission to use the content on your behalf."
            },
            {
                title: "How much content do I need to produce?",
                content: "The quantity of content you generate significantly impacts your success in the industry. While the exact amount is at your discretion, it is advisable to aim for 15-30 pieces of content per week. Generally, we establish a minimum requirement in your contract to ensure you achieve the minimum success rate as an Onyx model, and producing additional content can enhance your growth."
            },                
            {
                title: "What type of content do I have to provide?",
                content: "The content you provide is entirely up to you. You’re account manager will collaborate with you to determine the most successful content for your comfort level. While certain types of content may yield greater success, we ensure that your content aligns with your preferences."
            }
        ],
    };

    const styles = {
        bgColor: 'rgb(240,240,240)',
        titleTextColor: "black",
        rowTitleColor: "black",
        rowContentColor: 'rgb(120,120,120)',
        arrowColor: "black",
        rowContentPaddingTop: "2%",
        rowContentPaddingBottom: "2%",
        titleTextSize: "46px"
    };

    const config = {
        // animate: true,
        // arrowIcon: "V",
        // tabFocus: true
    };


    return (
        <div className="faq-section-2">
            <div className="faq-main">
                <div className="faq-section-1">
                    <div className="faq-section-1-inner">
                        <div className='services-title'>FAQ</div>
                    </div>
                     <div className="triangle-container"> <div className="triangle-img-white"></div>
                    </div>
                </div>
                <div className="faq-section-2-inner">
                    <p style={{ "marginBottom": "5%" }} />
                    <Faq
                        data={contactData}
                        styles={styles}
                        config={config}
                    />

                    <p style={{ "marginBottom": "5%" }} />
                    <Faq
                        data={applicationData}
                        styles={styles}
                        config={config}
                    />

                    <p style={{ "marginBottom": "5%" }} />
                    <Faq
                        data={commitmentData}
                        styles={styles}
                        config={config}
                    />

                    <p style={{ "marginBottom": "5%" }} />
                    <Faq
                        data={paymentData}
                        styles={styles}
                        config={config}
                    />

                    <p style={{ "marginBottom": "5%" }} />
                    <Faq
                        data={accountData}
                        styles={styles}
                        config={config}
                    />

                    <p style={{ "marginBottom": "5%" }} />
                    <Faq
                        data={startingData}
                        styles={styles}
                        config={config}
                    />

                    <p style={{ "marginBottom": "5%" }} />
                    <Faq
                        data={contentData}
                        styles={styles}
                        config={config}
                    />

                    <p style={{ "marginBottom": "15%" }} />
                </div>
            </div>
            <Contact/>
            <Footer />
        </div>

    );
}