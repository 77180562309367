import './Footer.css';
import logo from '../../images/logo-2.png';
import brand from '../../images/brand-name3.png';
import tiktok from '../../images/Social-tiktok.png';
import instagram from '../../images/Social-inst.png';
import social_x from '../../images/Social-X.png';
import { Link } from 'react-router-dom';

export default function Footer(props) {


    /*
     <div className='footer-newsletter-title'>Newsletter</div>
                                <div className='footer-newsletter-text'>A newsletter is an email providing updates and valuable content.</div>
                                <div className='footer-newsletter-signup'>
                                    <input className='footer-email-input' /> <button className='signup-button'>Signup</button>
    */

    return (
        <>
            <div className="footer-container-wrapper">
                <div className="footer-flex-container">
                    <div className='footer-logo-flex'>
                        <img className='footer-logo-img' src={logo}></img> <img className='footer-logo-text' src={brand} />
                    </div>

                    <div className='footer-lower-container'>
                        <div className="footer-section-left">
                            Welcome to ONYX, where we are dedicated to enhancing your success and elevating your fanbase on the platform. We offer a range of exclusive services to help you achieve your goals with ease and confidence.

                        </div>
                        <div className='footer-section-mid'>
                            <ul className='footer-nav-list'>
                                <li className='footer-nav-item'><Link to="/">Home</Link></li>
                                <li className='footer-nav-item'><Link to="/services">Services</Link></li>
                                <li className='footer-nav-item'><Link to="/about">About</Link></li>
                                <li className='footer-nav-item'><Link to="/faq">FAQ</Link></li>
                            </ul>

                        <div className="footer-links">
                            
                            <div className='social-media-links'>
                            Follow us on social media
                                <a href="#" className="social-icon"><img src={social_x} /></a>
                                <a href="#" className="social-icon"><img src={instagram} /></a>
                                <a href="#" className="social-icon"><img src={tiktok} /></a>
                            </div>
                            <div className="footer-section-right">

                            </div>

                            </div>
                        </div>
                    </div>
                    <div className='copyright-notice'> © 2023 Onyx Management. All rights reserved. </div>

                </div>

            </div>
        </>
    );
}