import './TestimonialBox.css';

export default function TestimonialBox(props) {

    var threeStar = (
        <>
            <span className="fa fa-star checked" />
            <span className="fa fa-star checked" />
            <span className="fa fa-star checked" />
            <span className="fa fa-star" />
            <span className="fa fa-star" />
        </>);

    var fourStar = (
        <>
            <span className="fa fa-star checked" />
            <span className="fa fa-star checked" />
            <span className="fa fa-star checked" />
            <span className="fa fa-star checked" />
            <span className="fa fa-star" />
        </>);

    var fiveStar = (
        <>
            <span className="fa fa-star checked" />
            <span className="fa fa-star checked" />
            <span className="fa fa-star checked" />
            <span className="fa fa-star checked" />
            <span className="fa fa-star checked" />
        </>);

    var rating = props.rating < 5 ? props.rating < 4 ? threeStar : fourStar : fiveStar;

    return (
        <div className="testimonial-container">
            <div className="testimonial-header">
                <div className="testimonial-icon-container">
                    <img className="testimonial-icon" src={props.icon} />
                </div>
                <div className='testimonial-top-container'>
                    <div className='testimonial-title-container'>
                        <div><h3 className="testimonial-title">{props.title}</h3>
                        </div>
                        <span className="testimonial-job-role"> {props.role}</span>
                    </div>
                </div>
                <div className="testimonial-rating-container">
                    {rating}
                </div>
            </div>            
            <div className='testimonial-underscore'></div>
            <p className="testimonial-body">{props.body}</p>
        </div>)
}