import ApplicationForm from '../../components/applicationform/ApplicationForm.js';
import './Application.css'
import Footer from '../../components/footer/Footer.js'
export default function Application() {


    return (
        <div className='app-main'>
            <ApplicationForm/>
            <Footer/>
        </div>
    );
}