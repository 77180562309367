import './Services.css';
import img3 from '../../images/Asset14onyx management.svg';
import img4 from '../../images/Social Media.svg';
import img5 from '../../images/Fan Engagement.png';
import img6 from '../../images/Security.png';
import Group168 from "../../images/ellipse_310.png";
import Group167 from "../../images/tri-vect.png";
import Group165 from "../../images/wave.png";
import Group166 from "../../images/lock.png";
import Group169 from "../../images/boxes.png";
import Contact from '../../components/contactsection/ContactSection.js';
import Footer from '../../components/footer/Footer.js';
import '../Shapes.css'
import { useEffect } from 'react';
import SplitType from 'split-type';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export default function Services() {

  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {


    let sec1Split = new SplitType(".services-title", { types: 'chars' });
    let sec1chars = sec1Split.chars;
    let ctx = gsap.context(
      gsap.from(sec1chars, { duration: 1, y: -5, opacity: 0, stagger: 0.03, ease: 'power1.out' }),
      gsap.from(".section-1-body", { duration: 2, opacity: 0, delay: 1 }),
      gsap.from(".sec-2-block-1", {
        opacity: 0,
        scrollTrigger: {
          trigger: ".sec-2-block-1",
          start: "top bottom",
          end: "bottom top",
          toggleActions: "play none none none"
        }
      }),
      gsap.from(".sec-2-block-2", {
        opacity: 0,
        scrollTrigger: {
          trigger: ".sec-2-block-2",
          start: "top bottom",
          end: "bottom top",
          toggleActions: "play none none none"
        }
      }),
      gsap.from(".sec-2-block-3", {
        opacity: 0,
        scrollTrigger: {
          trigger: ".sec-2-block-3",
          start: "top bottom",
          end: "bottom top",
          toggleActions: "play none none none"
        }
      }),
      gsap.from(".sec-2-block-4", {
        opacity: 0,
        scrollTrigger: {
          trigger: ".sec-2-block-4",
          start: "top bottom",
          end: "bottom top",
          toggleActions: "play none none none"
        }
      }),
      gsap.from(".sec-2-block-5", {
        opacity: 0,
        scrollTrigger: {
          trigger: ".sec-2-block-5",
          start: "top bottom",
          end: "bottom top",
          toggleActions: "play none none none"
        }
      })
    );



    const handleScroll = (event) => {

    }
  }, []);


  return (
    <div>
      <div className='services-section-1'>
        <div className='services-section-1-inner'>
          <div className='services-title'> Services </div>
          <div className="section-1-body-text services-body semi-transparent services-desc">
            Welcome to ONYX, where we are dedicated to enhancing your success and elevating your fanbase on the platform. We offer a range of exclusive services to help you achieve your goals with ease and confidence.
          </div>
          <div className='triangle-img-white'>

          </div>
        </div>
      </div>
      <div className='services-section-2'>
        <div className='services-section-2-inner'>

          <div className='services-section-2-block sec-2-block-anim-left sec-2-block-1'>
            <div className='services-s2-text-desc'>
              <div className='services-s2-text-title'>
                <span className='title-p text-black'>Transparent <span className='title-text-italic text-black'>Contracts</span> </span>
              </div>
              <div className='services-s2-text-body'>
                At ONYX, we prioritize transparency and fairness in all our business dealings. We ensure that our contracts are straightforward, easy to comprehend, and designed to protect both parties. Working with us guarantees you a great deal and peace of mind
              </div>
            </div>
            <div className='services-s2-img-container'>
              <img src={Group168} className="s2-img"></img>

            </div>
          </div>

          <div className='services-section-2-block sec-2-block-anim-right sec-2-block-2'>

            <div className='services-s2-img-container'>
              <img src={Group167} className="s2-img"></img>

            </div>
            <div className='services-s2-text-desc'>
              <div className='services-s2-text-title'>
                <span className='title-p text-black'>Customized Marketing <span className='title-text-italic text-black'>Strategies</span> </span>
              </div>
              <div className='services-s2-text-body'>
                At ONYX, we prioritize transparency and fairness in all our business dealings. We ensure that our contracts are straightforward, easy to comprehend, and designed to protect both parties. Working with us guarantees you a great deal and peace of mind
              </div>
            </div>
          </div>

          <div className='services-section-2-block sec-2-block-anim-left sec-2-block-3'>
            <div className='services-s2-text-desc'>
              <div className='services-s2-text-title'>
                <span className='title-p text-black'>Social Media <span className='title-text-italic text-black'>Management</span> </span>
              </div>
              <div className='services-s2-text-body'>
                At ONYX, we prioritize transparency and fairness in all our business dealings. We ensure that our contracts are straightforward, easy to comprehend, and designed to protect both parties. Working with us guarantees you a great deal and peace of mind
              </div>
            </div>
            <div className='services-s2-img-container'>
              <img src={Group165} className="s2-img"></img>

            </div>
          </div>

          <div className='services-section-2-block sec-2-block-anim-right sec-2-block-4'>

            <div className='services-s2-img-container'>
              <img src={Group169} className="s2-img"></img>

            </div>
            <div className='services-s2-text-desc'>
              <div className='services-s2-text-title'>
                <span className='title-p text-black'>Fan <span className='title-text-italic text-black'>Engagement</span> </span>
              </div>
              <div className='services-s2-text-body'>
                At ONYX, we prioritize transparency and fairness in all our business dealings. We ensure that our contracts are straightforward, easy to comprehend, and designed to protect both parties. Working with us guarantees you a great deal and peace of mind
              </div>
            </div>
          </div>


          <div className='services-section-2-block sec-2-block-anim-left sec-2-block-5'>
            <div className='services-s2-text-desc'>
              <div className='services-s2-text-title'>
                <span className='title-p text-black'><span className='title-text-italic text-black'>Security</span> </span>
              </div>
              <div className='services-s2-text-body'>
                At ONYX, we prioritize transparency and fairness in all our business dealings. We ensure that our contracts are straightforward, easy to comprehend, and designed to protect both parties. Working with us guarantees you a great deal and peace of mind
              </div>
            </div>
            <div className='services-s2-img-container'>
              <img src={Group166} className="s2-img"></img>

            </div>
          </div>



        </div>
      </div>

      <Contact />

      <div className="section">
        <Footer />
      </div>
    </div>
  );
}