import './About.css';
import "../Shapes.css"
import pexels from '../../images/pexels.jpg';
import testimony_male_1 from '../../images/male1.png';
import testimony_female_1 from '../../images/female1.png';
import testimony_male_2 from '../../images/user4.jpg';
import mLogo from '../../images/MLogo.png';
import Testimony from "../../components/TestimonySection/TestimonySection.js"
import ContactSection from '../../components/contactsection/ContactSection';
import Footer from '../../components/footer/Footer.js';

export default function About() {

    return (
        <div className='about-main-container'>
            <div className='about-section-one-container'>
                <div className='about-section-one-title'>
                    About Us
                </div>
                <div className='image-mask'>
                    <div className='triangle-img-white' />
                </div>
            </div>

            <div className="about-section-two-container">
                <div className='about-section-two-left'>
                    <div className="about-section-2-title">
                        Who We Are
                    </div>

                    <div className="about-section-2-hero">
                        <div className="about-section-2-hero-inner">
                            The most effective management team on OnlyFans
                        </div>
                    </div>

                    <div className="about-section-2-body">
                    Welcome to ONYX Management, the unrivaled management powerhouse dedicated to uplifting and supporting content creators in the adult entertainment industry. With a track record of excellence, we have successfully guided over 100 influencers to unparalleled heights of success. Our extensive portfolio boasts more than 50 glowing reviews from satisfied clients worldwide, spanning across many countries. At Onyx, we specialize in providing tailored solutions that propel individuals on OnlyFans and similar platforms to new realms of achievement. By leveraging our expertise and industry connections, we empower our clients to build formidable brands, expand their global reach, and unlock extraordinary financial opportunities. Join our prestigious roster today and experience the transformative power of our unwavering commitment to your success. To learn more about our game plan to success, please visit our Services page. 
                    </div>

                </div>
                <div className='about-section-two-right'>
                    <img src={mLogo} />
                </div>
            </div>
            <Testimony />
            <div className='black'>
                <ContactSection />
            </div>
            <Footer />
        </div>
    );
}