import logo from './images/Onyx_Logo.png';
import logo_name from './images/site-logo-name.png'
import './App.css';
import { useState, useEffect } from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import Media from 'react-media';
import Login from './pages/login/Login.js';
import Home from "./pages/Home.js";
import Dashboard from './pages/dashboard/Dashboard.js';
import ProtectedRoute from './middleware/protectedroute/ProtectedRoute.js';
import NavigationBar from "./components/navigation/NavigationBar.js";
import About from './pages/about/About.js';
import Services from './pages/services/Services.js';
import Footer from './components/footer/Footer.js';
import Faq from './pages/faq/FrequentlyAsked.js';
import Application from './pages/application/Application.js';

function App() {

  const [username, SetUsername] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [accountLevel, setAccountLevel] = useState(-1);
  const [sessionChecked, setSessionChecked] = useState(false);

  useEffect(() => {
    async function getSessionInfo() {
      if (sessionChecked) return;
      try {
        let url = process.env.REACT_APP_IPADDRESS + "/api/user/sessionInfo";
        let res = await fetch(url, { credentials: 'include' });

        if (res.status == 201) {
          let data = await res.json();
          changeAuthState(data.isLoggedIn, data.username, data.accountLevel);
          setSessionChecked(true);
        }
      }
      catch (err) {
        console.log(err);
      }
    }
    getSessionInfo();
  });

  function changeAuthState(_isLoggedIn, _username, _accountLevel) {
    setIsLoggedIn(_isLoggedIn);
    SetUsername(_username)
    setAccountLevel(_accountLevel)
  }

  //<img className="logo_name" src={logo_name} /> 

  return (<>
    <div className="App">
      <div className="NavContainer">
        <div className='logoContainer'>
       <Link to="./"> <img className="logo" src={logo} /> </Link>
       <div className="logoText">  <Link to="./">ONYX <span className='goldText'> MANAGEMENT</span> </Link></div>
        </div>
          <NavigationBar isLoggedIn={isLoggedIn}/>
      </div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login stateCallback={changeAuthState} isLoggedIn={isLoggedIn} />} />
        <Route path="/about" element={<About/>}/>
        <Route path="/services" element={<Services/>}/>
        <Route path="/faq" element={<Faq/>} />
        <Route path="/application" element={<Application/>}/>
        <Route path="/dashboard" element={<ProtectedRoute isLoggedIn={isLoggedIn}><Dashboard/> </ProtectedRoute>} />
      </Routes>

    </div>
  </>
  );
}

export default App;
