import '../navigation/NavigationBar.css'
import gsap from 'gsap';
import { Link } from 'react-router-dom';

export default function ApplyButton(props){

    const handleMouseEnter = (button) => {
        gsap.to(button, {
          backgroundColor: '#FFD700', // Lighter color on hover
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', // Slight shadow
        });
      };
    
      const handleMouseLeave = (button) => {
        gsap.to(button, {
          backgroundColor: '#B3863B', // Dark gold on leave
          boxShadow: '0 0 0 rgba(0, 0, 0, 0)', // Remove shadow
        });
      };

    return (
        <Link to="/application"> <button className="applyBtnNav" onMouseEnter={(e) => handleMouseEnter(e.target)}
      onMouseLeave={(e) => handleMouseLeave(e.target)}>Apply Now</button></Link>
    )
} 