import LoginPanel from '../../components/loginpanel/LoginPanel.js';
import './Login.css';

export default function Login(props) {
    
    return(
    <div className='loginPanelOuter'>
        <div className='innerLogin'>
        <LoginPanel stateCallback={props.stateCallback} isLoggedIn={props.isLoggedIn}/>
    </div>
    </div>
    )
}