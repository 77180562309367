import './ContactSection.css';
import { useState } from 'react';

export default function ContactSection(props) {

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [firstNameError, setFirstNameError] = useState(false);
    const [emailError, setEmailError] = useState(null);
    const [subjectError, setSubjectError] = useState(null);
    const [messageError, setMessageError] = useState(null);
    const [prefContactError, setPrefContactError] = useState(null);
    const [fSubmitError, setFSubmitError] = useState(null);


    function resetStates()
    {
        setFirstNameError(null);
        setEmailError(null);
        setSubjectError(null);
        setMessageError(null);
        setPrefContactError(null);
    }

    function onClickCheckbox(id)
    {
        if(document.getElementById(id).checked)
        {
            var other = id == "prefmail" ? document.getElementById("prefphone") : document.getElementById("prefmail");
            other.checked = false;
        }
    }


    function assertForm()
    {
        var verified = true;

        if(document.getElementById("firstname").value.match(/\d/g) || document.getElementById("firstname").value == "") 
       {
        setFirstNameError("Please enter a name");
        verified = false;
       }

       if(document.getElementById("email").value == "" || document.getElementById("email").value.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/) == null)
       {
          setEmailError("Email appears to be invalid. Please check and try again.")
          verified = false;
       }

       if(document.getElementById("subject").value == "")
       {
        setSubjectError("Please enter a subject.");
        verified = false;        
       }

       if(document.getElementById("message").value == "")
       {
        setMessageError("Please enter a message");
        verified = false;
       }

       if(!document.getElementById("prefmail").checked && !document.getElementById("prefphone").checked)
       {
        setPrefContactError("Please select a preferred contact");
        verified = false;
       }

       return verified;
    }

    function onClickSubmit(e) 
    { 
        e.preventDefault();

        resetStates();
        if(assertForm())
        {
            fetch("/api/v1/contact/", {
                method:"POST",
                headers:{
                    "Content-Type":"application/json"
                },
                body: JSON.stringify({
                    data: {
                        first_name: document.getElementById("firstname").value,
                        last_name: document.getElementById("surname").value,
                        contact_email: document.getElementById("email").value,
                        contact_no: document.getElementById("phone").value, 
                        message: document.getElementById("subject") + "| " + document.getElementById("message").value,
                        prefered_contact: document.getElementById("prefphone").checked ? "phone" : "email"
                    }
                })        
            })
            .then( (res) => {
                if(res.status == 201) {
                    setIsSubmitted(true);                
                    return;
                }
                // otherwise error
                setFSubmitError(true);
                console.log("there was an error");
                return;
            });
            return;
        }



    }



    return (

        <div className="contact-section-main">
            {isSubmitted ? <div className="contact-sent">Thank you. We'll be in touch soon.</div> :
                <div className="contact-section-inner">
                    <div className="contact-section-left-column"></div>
                    <div className="contact-section-middle-column">
                        <div className="contact-section-title">
                            Let's get in <span className='title-poppins'>touch</span>
                        </div>

                        <div className="contact-section-desc">
                            Engage in effortless communication with us through our 'Contact Us' section, where starting a meaningful conversation is just a click away."
                        </div>

                        <div className="contact-section-form-container">
                            <form className='form-outer'>

                                <div className="form-row-1 form-split-row">
                                    <div className="form-r1-c1 form-half-width">
                                        <div className='form-input-title'>Name* {firstNameError ? <span className='form-error-text'>{firstNameError} </span> : <></>}</div>
                                        <input className="input-form" id="firstname" placeholder="Michael" type="text" />
                                    </div>
                                    <div className="form-r1-c2 form-half-width">
                                        <div className='form-input-title'>Surname</div>
                                        <input className="input-form" id="surname" placeholder="Johnson" type="text" />
                                    </div>
                                </div>

                                <div className="form-row-2 form-split-row">
                                    <div className="form-r2-c1 form-half-width">
                                        <div className='form-input-title'>E-mail* {emailError ? <span className='form-error-text'> {emailError} </span> : <></>}</div>
                                        <input className="input-form" id="email" placeholder="example@example.com" type="text" />
                                    </div>
                                    <div className="form-r2-c2 form-half-width">
                                        <div className='form-input-title'>Phone </div>
                                        <input className="input-form" id="phone" placeholder="000 246 7894" type="text" />
                                    </div>
                                </div>

                                <div className="form-row-3 form-full-row">
                                    <div className="form-r2-c1">
                                        <div className='form-input-title'>Subject* {subjectError ? <span className='form-error-text'> {subjectError} </span> : <></> }</div>
                                        <input className="input-form" id="subject" placeholder="Your subject here" type="text" />
                                    </div>
                                </div>

                                <div className="form-row-3 form-full-row">
                                    <div className="form-r2-c2">
                                        <div className='form-input-title'>Message* {messageError ? <span className='form-error-text'> {messageError} </span> : <></>}</div>
                                        <textarea className="input-form form-textarea" id="message" rows="5" placeholder="Type here" type="text" />
                                    </div>
                                </div>

                                <div className='form-input-contact-preference'>
                                    <span className='contact-preference-text'>Prefered contact {prefContactError ? <span className='form-error-text'> {prefContactError} </span> : <></>}</span>
                                    <input className="preference-checkbox" id="prefmail" type="checkbox" onClick={()=> onClickCheckbox("prefmail")} /> <span className='form-input-contact-preference-type'>Email</span>
                                    <input className="preference-checkbox" id="prefphone" type="checkbox" onClick={()=> onClickCheckbox("prefphone")} />  <span className='form-input-contact-preference-type'>Phone</span>
                                    <button className='button-apply' type="button" onClick={onClickSubmit.bind(this)}>Send</button>
                                </div>


                            </form>
                        </div>
                    </div>
                    <div className="contact-section-right-column">
                    </div>

                </div>}

        </div>
    )
}