import "./Home.css"
import "./Shapes.css"
import video1 from "../video/video1.mov"
import wave from '../images/wave-2.svg';  //
import pexels from '../images/pexels.jpg';
import FeatureBox from '../components/featurebox/FeatureBox.js';
import img_magnifying from '../images/icons-magnifying_glass.png';
import s3image from '../images/s3-image.jpeg';
import bunnyGirl from '../images/bunny.jpeg';
import { useEffect, useState } from 'react';
import gsap from 'gsap';
import SplitType from 'split-type';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import TestimonialBox from "../components/testimonialbox/TestimonialBox.js";
import testimony_male_1 from '../images/male1.png';
import testimony_female_1 from '../images/female1.png';
import ContactForm from '../components/contactform/ContactForm.js';
import Footer from '../components/footer/Footer.js';
import Group168 from "../images/Group 168.png";
import Group167 from "../images/Group 167.png";
import Group165 from "../images/Group 165.png";
import Group166 from "../images/icon_wve.png";
import hs3_1 from "../images/hs3-1.png";
import hs3_2 from "../images/hs3-2.png";
import hs3_3 from "../images/hs3-3.png";
import hs3_4 from "../images/hs3-4.png";
import hs3_5 from "../images/hs3-5.png";
import mLogo from '../images/MLogo.png';
import TestimonySection from '../components/TestimonySection/TestimonySection.js';
import ContactSection from "../components/contactsection/ContactSection.js";
import {Link} from 'react-router-dom';
import ApplyButton from "../components/applybutton/ApplyButton.js"
export default function Home(props) {

  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {

    let sec1Split = new SplitType(".section-1-title", { types: 'words, chars'});
    let sec1chars = sec1Split.chars;

    let ctx = gsap.context(
      gsap.from(sec1chars, { duration: 1, y: -5, opacity: 0, stagger: 0.03, ease: 'power1.out' }),
      gsap.from(".button-apply", { duration: 3, opacity: 0, delay: 1 }),
      gsap.from(".section-1-body", { duration: 2, opacity: 0, delay: 2 })
    );
    const handleScroll = (event) => {

      setScrollTop(window.scrollY);

    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  var activeVideo = video1;
  var bgImgIndex = -5;

  if (scrollTop > 800) {
    bgImgIndex = -5;

  }

  return (
    <>
      <div className="home-main">
        <div className="home-section-1">
          <div className="section-1-title-container">
            <div className="section-1-title">
              Empower. Elevate. <p className="title-p" /> Excel. <span className="title-text-italic">With</span> ONYX
            </div>
            <ApplyButton/>
          </div>
          <div className="section-1-body-text">
            Welcome to ONYX, where we are dedicated to enhancing your success and elevating your fanbase on the platform. We offer a range of exclusive services to help you achieve your goals with ease and confidence.
          </div>

        </div>

        <div className="home-section-2">
          <div className="triangle-img-white" />
          <div className="section-2-main">
            <div className="section-2-main-inner">
              <div className="section-2-title">
                <span className="section-2-title-1">What</span>
                <span className="section-2-title-2"> We</span>
                <span className="section-2-title-3"> Do</span>
              </div>
              <p />
              <div className="section-2-body">
              From marketing to management Onyx has you covered, providing you with personal marketing strategies, 24/7 management team to cater to your needs, transparent contracts to ensure your safety and a proven track record of boosting fan engagement.


                <p className="title-p" />We offer a range of exclusive services to help you achieve your goals with ease and confidence.
              </div>
              <p />
              <div className="section-2-features-wrapper">
                <div className="section-2-features">


                  <div className="section-2-featurebox">
                    <div className="section-2-featurebox-inner">
                      <div className="section-2-feature-icon"><img src={Group165} /></div> <p />
                      <div className="section-2-feature-body">
                        <span className="section-2-feature-header">Customized Marketing Strategies</span>
                        <p />
                        <span className="section-2-feature-desc">Our team of professional marketers understands the importance of tailoring strategies to your unique needs and objectives. </span>
                      </div>
                    </div>
                  </div>


                  <div className="section-2-featurebox">
                    <div className="section-2-featurebox-inner">
                      <div className="section-2-feature-icon"> <img src={Group166} /></div> <p />
                      <div className="section-2-feature-body">
                        <span className="section-2-feature-header">Social Media Management</span>
                        <p />
                        <span className="section-2-feature-desc">Social media is a crucial tool for promoting your OnlyFans account, and we understand that.</span>
                      </div>
                    </div>
                  </div>

                  <div className="section-2-featurebox">
                    <div className="section-2-featurebox-inner">
                      <div className="section-2-feature-icon"> <img src={Group167} /></div> <p />
                      <div className="section-2-feature-body">
                        <span className="section-2-feature-header">Transparent Contracts</span>
                        <p />
                        <span className="section-2-feature-desc">At ONYX, we prioritize transparency and fairness in all our business dealings. </span>
                      </div>
                    </div>
                  </div>


                  <div className="section-2-featurebox">
                    <div className="section-2-featurebox-inner">
                      <div className="section-2-feature-icon"><img src={Group168} /></div> <p />
                      <div className="section-2-feature-body">
                        <span className="section-2-feature-header">Fan Engagement</span>
                        <p />
                        <span className="section-2-feature-desc">At ONYX, we believe in building strong, long-lasting relationships with your fans.</span>
                      </div>
                    </div>
                  </div>

                </div>
              </div>


            </div>
            <div className="diamond-container">
              <div className="diamond-img-white"></div>
            </div>
          </div>

        </div>

        <div className="home-section-3">
          <div className="home-section-3-title">
            Who We Are
          </div>

          <div className="home-section-3-hero">
            <div className="home-section-3-hero-inner">
              The most effective management team on OnlyFans
            </div>
          </div>

          <div className="home-section-3-body">
          Welcome to ONYX Management, the unrivaled management powerhouse dedicated to uplifting and supporting content creators in the adult entertainment industry. With a track record of excellence, we have successfully guided over 100 influencers to unparalleled heights of success. Our extensive portfolio boasts more than 50 glowing reviews from satisfied clients worldwide, spanning across many countries. At Onyx, we specialize in providing tailored solutions that propel individuals on OnlyFans and similar platforms to new realms of achievement. By leveraging our expertise and industry connections, we empower our clients to build formidable brands, expand their global reach, and unlock extraordinary financial opportunities. Join our prestigious roster today and experience the transformative power of our unwavering commitment to your success. To learn more about our game plan to success, please visit our Services page. 
          </div>

          <div className="home-section-3-windows">
            <img className="sec-3-window-img-1" src={hs3_1} />
            <img className="sec-3-window-img-2" src={hs3_5} />
            <img className="sec-3-window-img-3" src={hs3_2} />
            <img className="sec-3-window-img-4" src={hs3_3} />
            <img className="sec-3-window-img-5" src={hs3_4} />
          </div>
        </div>

        <div className="home-section-4">
          <div className="home-section-4-outer">
            <div className="home-section-4-inner-left">
              <span className="section-4-text-large">
                Are you an aspiring <span className="section-4-text-large poppins-gold">OnlyFans</span>  model seeking to elevate your brand to new heights?
              </span>
              <p />
              <span className="section-4-text-medium">
              Do you envision growing your following, maximizing your earnings, and leaving a lasting impression in the adult content industry? Your search ends here - ONYX Management is dedicated to empowering you on your path to success. 
<p/> <br/>Embark on a thrilling and fulfilling professional journey by taking the initial leap. Apply today and unlock the boundless opportunities that await you with ONYX Management!

              </span>
              <p />
              <ApplyButton/>

            </div>
            <div className="home-section-4-inner-right">
              <img className="home-section-4-img" src={mLogo} />
            </div>
          </div>
        </div>

        <TestimonySection />

        <ContactSection />

        <Footer />

      </div>
    </>
  )
}