import { useState } from 'react'
import "./LoginPanel.css"
import { Navigate } from 'react-router-dom';

export default function LoginPane({ stateCallback, isLoggedIn }) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [badCredentials, setBadCredentials] = useState(false);
    const [usernameRequiredFieldBlank, setUsernameRequiredFieldBlank] = useState(false);
    const [passwordRequiredFieldBlank, setPasswordRequiredFieldBlank] = useState(false);

    if (isLoggedIn) {
        return <Navigate to="/dashboard" replace />
    }


    const submitLogin = async (event) => {
        event.preventDefault();

        // check if any required field is blank.
        if (username == "" || password == "") {
            if (username === "") {
                setUsernameRequiredFieldBlank(true);
            }
            if (password === "") {
                setPasswordRequiredFieldBlank(true);
            }
            return;
        }

        // field values are ok
        try {
            console.log(username);
            console.log(password);
            let response = await fetch("/api/v1/user/login",
                {
                    method: "POST",
                    credentials: 'same-origin',
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify
                        ({
                            username: username,
                            password: password
                        })
                });
            let responsejson = await response.json();
            // if 201 then redirect to the home page
            if (response.status == 201) {
                /*
                if (process.env.REACT_APP_PRODUCTION == 1) {
                    stateCallback(true, responsejson.username, responsejson.accountLevel);
                    return;
                }
                */
                stateCallback(true, responsejson.username, responsejson.accountLevel);
                return;
            };

            // invalid credentials
            if (response.status == 401) {
                // set bad credentials
                setBadCredentials(true);
                return;
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    return (
        <div>
            <div className='loginTopSpace'> </div>
        <div className='loginPanelContainer'>
            {badCredentials == true ? <>Bad login</> : <></>}
            <form onSubmit={submitLogin}>
                {usernameRequiredFieldBlank == true ? <>Please provide a username <p /> </> : <><p /></>}
                <input className="LoginInput" type="text" onChange={(e) => { setUsername(e.target.value); setUsernameRequiredFieldBlank(false); }}></input> <br />
                {passwordRequiredFieldBlank == true ? <>Please provide a password <p /> </> : <><p /></>}
                <input className="LoginInput" type="password" onChange={(e) => { setPassword(e.target.value); setPasswordRequiredFieldBlank(false); }}></input> <br />
                <button className="LoginButton" type="submit">Login</button>
            </form>
        </div>
        </div>
    )
}