import './RecordView.css';

export default function RecordView(props) {

    function deleteRecord() {

        if(window.confirm("Are you sure you want to delete this record?")) {
            fetch( `/api/v1/${props.mode}/${props.data.id}`, {
                method: "DELETE"
            })
            .then((res) => { 
                if(res.status == 204) {
                    window.location.reload(true);
                }
            });
        }
    }

    function markAsContacted() {
        if(window.confirm("Are you sure you want to mark this record as Contacted")) {
            fetch( `/api/v1/${props.mode}/${props.data.id}/contacted`, {
                method: "PATCH"
            })
            .then((res) => { 
                if(res.status == 200) {
                    window.location.reload(true);
                }
            });
        }
    }

    function markAsAccepted() {
        if(window.confirm("Are you sure you want to mark this record as Accepted")) {
            fetch( `/api/v1/${props.mode}/${props.data.id}/accepted`, {
                method: "PATCH"
            })
            .then((res) => { 
                if(res.status == 200) {
                    window.location.reload(true);
                }
            });
        }
    }

    return (
        <div className="data-record-container">

            {props.mode == "application" && props.data.accepted ? <span> ACCEPTED </span> : <></>}
            {props.data.contacted ? <span> CONTACTED </span> : <></>}
            <span>Name: {props.data.first_name} {props.data.last_name}</span> <p/>
            <span>Contact (Phone): {props.data.contact_number}</span> <p/>
            <span>Contact (Email): {props.data.contact_email}</span> <p/>
            <span>Prefered contact: {props.data.prefered_contact}</span>
            <p/>
            <span> Message: {props.data.message} </span>

        <div className="action-container">
            <a className="action-button contact-record" onClick={()=>{markAsContacted()}}>Mark as Contacted</a>
           {props.mode == "application"  ? <a className="action-button accept-record" onClick={()=>{markAsAccepted()}}>Mark as Accepted</a> : <></>} 
           <a className="action-button delete-record" onClick={()=>{deleteRecord()}}>Delete</a>
        </div>
        

        </div>
);
}