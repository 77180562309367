import TestimonialBox from '../testimonialbox/TestimonialBox.js';
import './TestimonySection.css';
import TestimonialData from '../../data/testimonials/TestimonialData.json';
import gsap from 'gsap';
import arrowLeft from '../../images/arrow-left.png';
import arrowRight from '../../images/arrow-right.png';
import { useEffect, useState } from 'react';
export default function TestimonySection(props) {

    const [slideIndex, setSlideIndex] = useState(1);

    var slideTimeout;

    useEffect(() => {
        showSlides(1);
        slideTimeout = setTimeout(nextSlide, 8000);
    }, [])

    // Next/previous controls
    function plusSlides(n) {
        clearTimeout(slideTimeout);
        showSlides(slideIndex += n);
    }

    // Thumbnail image controls
    function currentSlide(n) {
        showSlides(slideIndex = n);
    }

    function previousSlide() {
        var no = slideIndex - 1;
        clearTimeout(slideTimeout);
        setSlideIndex(no);
        showSlides(no);
    }

    function nextSlide() {
        var no = slideIndex + 1;
        clearTimeout(slideTimeout);
        setSlideIndex(no);
        showSlides(no);
    }


    function showSlides(n) {
        let i;
        let slides = document.getElementsByClassName("testimonial-container");
        if (n > slides.length) {setSlideIndex(1); n = 1;}
        if(n <= 0) {setSlideIndex(slides.length); n = slides.length;}
        for (i = 0; i < slides.length; i++) {
            if(slides[i])
                slides[i].style.display = "none";
        }
        if(slides[n - 1])
            slides[n - 1].style.display = "block";
    }

 

    return (
        <div className="testimony-section-main">
            <div className='testimony-section-flex-container'>
                <div className='testimony-section-navbox'>
                    <div className='testimony-navbox-title'>
                        What our clients say about us
                    </div>
                    <p />
                    <div className='testimony-navbox-body'>
                        Our clients' testimonials and feedback stand as a testament to the outstanding quality of service and unwavering dedication we consistently deliver.
                    </div>

                    <div className='testimony-navbox-arrow-container'>
                        <button className='testimony-nav-arrow' onClick={()=>{previousSlide()}}><img src={arrowLeft}/> </button>
                        <button className='testimony-nav-arrow' onClick={()=>{nextSlide()}}><img src={arrowRight}/> </button>
                    </div>
                </div>
                <div className='testimony-section-review-container'>
                    {TestimonialData.map((object, index) => {
                        return (<TestimonialBox key={index} title={object.name} role={object.job} icon={object.icon} body={object.body} rating={object.rating} />)
                    })}

                </div>
            </div>




        </div>
    )
}