import './ContactList.css';

export default function ContactList(props) {

    return (
        <>
                <div className="list-container">
                    <div className="list-table-filter">
                        <ul>
                            <li> <button className="list-table-filter-button" style={props.activeListType == "App" ? {"color":"blue"} : {"color":"white"}} onClick={() => { props.setListType("App") }}>Apps</button> </li>
                            <li> <button className="list-table-filter-button" style={props.activeListType == "Query" ? {"color":"blue"} : {"color":"white"}} onClick={() => { props.setListType("Query") }}>Queries</button> </li>
                        </ul>
                    </div>
                    <div className="list-filters">
                        <ul>
                            <li className="dash-nav-list-item"><button className="list-item-filter-button"  onClick={() => { props.filterNone() }}>All </button></li>
                            {props.activeListType == "App" ? <li className="dash-nav-list-item"> <button className="list-item-filter-button"  onClick={() => { props.filterUnacceptedApps() }}>{props.numUnaccepted} unaccepted </button> </li> : <></>}
                            <li className="dash-nav-list-item"> <button className="list-item-filter-button" onClick={() => { props.filterUncontacted() }}>{props.numUncontacted} uncontacted</button> </li>
                        </ul>
                    </div>
                    <div>
                        {props.filteredList ? props.filteredList.map((app, index) => (
                            <li key={index}> <button onClick={()=> {props.loadRecord(app.id)}}> [{app.id}] {app.first_name} {app.last_name} </button></li>
                        )) : <></>}
                    </div>
                </div>
        </>
    );
}