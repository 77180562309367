import { useState } from 'react';
import '../../pages/application/Application.css'
import '../contactform/ContactForm.js'
import '../../pages/Shapes.css'

export default function ApplicationForm(props) {

    const [fnameError, setFnameError] = useState(false);
    const [fmailError, setFmailError] = useState(false);
    const [fnumError, setFnumError] = useState(false);
    const [fsubError, setFsubError] = useState(false);
    const [fmsgError, setFmsgError] = useState(false);
    const [fsubmitted, setFsubmitted] = useState(false);
    const [fsubmitError, setFSubmitError] = useState(false);
    const [prefContactError, setPrefContactError] = useState(false);

    function onClickCheckbox(id)
    {
        if(document.getElementById(id).checked)
        {
            var other = id == "prefmail" ? document.getElementById("prefphone") : document.getElementById("prefmail");
            other.checked = false;
        }
    }



    function validateApplication(e) {
        e.preventDefault();



        var valid = true;

        setFnameError(false);
        setFmailError(false);
        setFnumError(false);
        setFsubError(false);
        setFmsgError(false);
        setPrefContactError(false);

        if (/\d/.test(document.getElementById("fname").value) || document.getElementById("fname").value == "") {
            setFnameError(true);
            valid = false;
        }
        var mailregex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

        if (!mailregex.test(document.getElementById("fmail").value) || document.getElementById("fmail").value == "") {
            setFmailError(true);
            valid = false;
        }

        if (/[^[0-9]*$/.test(document.getElementById("fno").value) && document.getElementById("fno").value == "") {
            setFnumError(true);
            valid = false;
        }

        if(!document.getElementById("prefmail").checked &&  !document.getElementById("prefphone").checked) {
            setPrefContactError(true);
            valid = false;
        }


        if (valid) {
            console.log("submitting");
            submitApplication();
            return;
        }
        return;
    }

    function submitApplication() {


        fetch("/api/v1/application/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                data: {
                    first_name: document.getElementById("fname").value,
                    last_name: document.getElementById("surname").value,
                    contact_email: document.getElementById("fmail").value,
                    contact_no: document.getElementById("fno").value,
                    message: buildMessage(),
                    prefered_contact: document.getElementById("prefmail").checked ? "email" : "phone"
                }
            })
        })
            .then((res) => {
                if (res.status == 201) {
                    setFsubmitted(true);
                    return;
                }
                // otherwise error
                setFSubmitError(true);
                console.log("there was an error");
                return;
            });
    }

    function buildMessage() {
        return "onlyfans: " + document.getElementById("fonlyfansaccount").value +
            " instagram: " + document.getElementById("finstagram").value +
            " tiktok: " + document.getElementById("ftiktok").value +
            " twitter: " + document.getElementById("ftwitter").value +
            " onlyfans revenue: " + document.getElementById("fonlyfansrevenue").value +
            " expected revenue: " + document.getElementById("fexpectedrevenue").value +
            " Invested time: " + document.getElementById("finvestedtime").value +
            "show face: " + document.getElementById("fshowface").value +
            "niche ? : " + document.getElementById("fniche").value +
            "about you: " + document.getElementById("faboutyou").value
    }

    return (
        <div className='app-container'>
            <div className='app-section-top'>
                <span className='application-title'>Application</span>

                <div className='triangle-img-white' />
            </div>
            <div className='app-form-container'>
                {fsubmitted ?
                    <div className="application-container">
                        <div className="form-section">
                            <div className="form-inner thanks-message">
                                Thank you for your application. We will usually respond within 48 hours.
                            </div>
                        </div>
                    </div>
                    :
                    <div className="application-container">

                        <div className="form-section">
                            <div className='app-title-text'> Where can we find you on social media?</div>
                            <div className="form-inner-social">
                                <input className="form-single-line-social" id="finstagram" placeholder="Instagram account" />
                            </div>
                            <div className="form-inner-social">
                                <input className="form-single-line-social" id="ftiktok" placeholder="Tiktok account" />
                            </div>
                            <div className="form-inner-social">
                                <input className="form-single-line-social" id="ftwitter" placeholder="Twitter account" />
                            </div>
                        </div>

                        <div className="form-section">
                            <div className='app-title-text'>Are you already on OnlyFans?</div>
                            <div className="form-inner-social">
                                <input className="form-single-line-social mid-width" id="fonlyfansaccount" placeholder="OnlyFans account" />
                            </div>
                            <div className="form-inner-social">
                                <input className="form-single-line-social mid-width" id="fonlyfansrevenue" placeholder="Monthly revenue" />
                            </div>
                        </div>

                        <div className="form-section">
                            <div className="form-inner-social-wide">

                                <div className='app-title-text'>How much do you want to earn per month?</div>
                                <select className="form-single-line-wide" id="fexpectedrevenue">
                                    <option> Select Amount </option>
                                    <option> $1000-$4999</option>
                                    <option> $5000-$14,999</option>
                                    <option> $15,000-$49,999</option>
                                    <option> $50,000+</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-section">
                            <div className="form-inner-social-wide">

                                <div className='app-title-text'>How much time do you plan on investing per day?</div>
                                <select className="form-single-line-social-wide" id="finvestedtime">
                                    <option>Select Time </option>
                                    <option>Up to 1 hour</option>
                                    <option>1-4 hours</option>
                                    <option>4-7 hours</option>
                                    <option>8 hours+</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-section">
                            <div className="form-inner-social-wide">

                                <div className='app-title-text'>Do you show your face online?</div>
                                <select className="form-single-line-social-wide" id="fshowface">
                                    <option>Select Option </option>
                                    <option>Yes - I show my face anywhere</option>
                                    <option>Yes - But only on OnlyFans</option>
                                    <option>Yes - But not on OnlyFans</option>
                                    <option>No - I don't show my face anywhere</option>
                                </select>
                            </div>


                        </div>

                        <div className="form-section">

                            <div className='app-title-text'>Are you catering to a specific niche?</div>
                            <div className="form-inner-wide">
                                <textarea cols="40" rows="5" className="form-multi-line" id="fniche" placeholder="Tell us about your target audience (Optional)" />
                                {fmsgError ? <span className="form-error">Please provide a message</span> : <></>}
                            </div>

                            <div className='app-title-text'>What should we know about you?</div>
                            <div className="form-inner-wide">
                                <textarea cols="40" rows="5" className="form-multi-line" id="faboutyou" placeholder="Tell us about you (Optional)" />
                                {fmsgError ? <span className="form-error">Please provide a message</span> : <></>}
                            </div>
                        </div>

                        <div className="form-section-flex">
                            <div className="form-inner-social-wide">
                                <input className="form-single-line-social-mid" id="fname" placeholder="name (Required)" required />
                                {fnameError ? <span className="form-error">Please enter a valid name</span> : <></>}
                            </div>
                            <div className="form-inner-social-wide">
                                <input className="form-single-line-social-mid" id="surname" placeholder="surname (Optional)" />
                                <></>
                            </div>
                            <div className="form-inner-social-wide">
                                <input className="form-single-line-social-mid" id="fmail" placeholder="E-mail (Required)" required />
                                {fmailError ? <span className="form-error">Please enter a valid E-mail</span> : <></>}
                            </div>
                            <div className="form-inner-social-wide">
                                <input className="form-single-line-social-mid" id="fno" placeholder="Contact Number (Required)" />
                                {fnumError ? <span className="form-error">Please enter a phone number </span> : <></>}
                            </div>
                            <></>
                        </div>
                        
                        <div className="form-section">
                        <div className='form-input-contact-preference'>
                                    <span className='contact-preference-text' style={{color:'black'}}>Prefered contact method: {prefContactError ? <span className='form-error-text'>Please select a prefered contact method.</span> : <></>}</span>
                                    <input className="preference-checkbox" style={{color:'black'}} id="prefmail" type="checkbox" onClick={()=> onClickCheckbox("prefmail")} /> <span className='form-input-contact-preference-type' style={{color:'black'}}>Email</span>
                                    <input className="preference-checkbox" style={{color:'black'}} id="prefphone" type="checkbox" onClick={()=> onClickCheckbox("prefphone")} />  <span className='form-input-contact-preference-type' style={{color:'black'}}>Phone</span>
                                    <button className='button-apply' type="button" onClick={validateApplication.bind(this)}>Send</button>
                                </div>
                        </div>
                    </div>

                }
            </div>

        </div>);
}